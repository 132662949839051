import { Controller } from '@hotwired/stimulus';
import morphdom from 'morphdom';
/**
 * DO NOT EXTEND THIS CONTROLLER. IF YOU HAVE TO, YOU'RE DOING STH. WRONG!!!
 *
 * This is controller that allows dynamic replacement of form contents.
 * You can use it to have form validation/updates without a full page reload.
 *
 * You need a new action in your spring controller to update the content.
 * The url is automatically inferred by your action. If the action points
 * to "/users", the live endpoint is "/users/live.
 *
 * Usage HTML:
 * <form data-controller="form-live" action="/users" data-form-live-morph-element-id-value="live-form-wrapper">
 *     <div id="live-form-wrapper>
 *      <#include "_live.html.ftl>
 *     </div>
 * </form>
 *
 * Usage Java:
 * @PostMapping("/live")
 * public String live() {
 *     return "user/_live";
 * }
 *
 */
export default class extends Controller {
  static values = {
    inputValidation: Boolean,
    multipartFormData: Boolean,
    morphElementId: String
  };
  inputValidationValue!: boolean;
  multipartFormDataValue!: boolean;
  morphElementIdValue!: string;
  abortController!: AbortController;
  timeout!: NodeJS.Timeout;

  connect() {
    const form = <HTMLFormElement>this.element;
    const liveUrl = this.ensureUrlWithSlashAtEnd(form.action) + 'live';
    const liveFormUpdate = this.liveFormUpdate;

    // DO NOT EXTEND THIS CONTROLLER. IF YOU HAVE TO, YOU'RE DOING STH. WRONG!!!
    form.addEventListener('focusout', (event) => {
      const elem = <HTMLInputElement>event.target;
      const list = [...form.querySelectorAll('input:not([type=radio], [type=checkbox], [data-form-live-excluded], .jdropdown-header)')];
      if (list.includes(elem) && elem.value !== elem.defaultValue) {
        if (this.abortController) {
          this.abortController.abort('Validation reason.');
        }
        this.abortController = new AbortController();
        this.debounce(() => {
          liveFormUpdate(this, form, liveUrl);
        })();
      }
    });

    // DO NOT EXTEND THIS CONTROLLER. IF YOU HAVE TO, YOU'RE DOING STH. WRONG!!!
    form.addEventListener('change', (event) => {
      const elem = <HTMLInputElement>event.target;
      const list = [...form.querySelectorAll('input[type=checkbox], input[type=radio], select, input[type=number], input[type=file], textarea')];
      if (list.includes(elem)) {
        if (this.abortController) {
          this.abortController.abort('Validation reason.');
        }
        this.abortController = new AbortController();
        this.debounce(() => {
          liveFormUpdate(this, form, liveUrl);
        })();
      }
    });

    // DO NOT EXTEND THIS CONTROLLER. IF YOU HAVE TO, YOU'RE DOING STH. WRONG!!!
    form.addEventListener('live', (_event) => {
      if (this.abortController) {
        this.abortController.abort('Validation reason.');
      }
      this.abortController = new AbortController();
      this.debounce(() => {
        liveFormUpdate(this, form, liveUrl);
      })();
    });

    if (this.inputValidationValue) {
      form.addEventListener('input', (event) => {
        const elem = <HTMLInputElement>event.target;
        const list = [...form.querySelectorAll('input:not([type=radio], [type=checkbox], [data-form-live-excluded])')];
        if (list.includes(elem) && elem.value !== elem.defaultValue) {
          if (this.abortController) {
            this.abortController.abort('Validation reason.');
          }
          this.abortController = new AbortController();
          this.debounce(() => {
            liveFormUpdate(this, form, liveUrl);
          })();
        }
      });
    }
  }

  formLiveUpdate(event: Event) {
    event.preventDefault();
    const form = <HTMLFormElement>this.element;
    const liveUrl = this.ensureUrlWithSlashAtEnd(form.action) + 'live';
    this.liveFormUpdate(this, form, liveUrl);
  }

  // DO NOT EXTEND THIS CONTROLLER. IF YOU HAVE TO, YOU'RE DOING STH. WRONG!!!
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  liveFormUpdate(scope: any, form: HTMLFormElement, url: string) {
    const postBody = scope.multipartFormDataValue
      ? new FormData(form) // @ts-expect-error it is fine to create URLSearchParams without size, as the server has a default value
      : new URLSearchParams(new FormData(form)).toString().replace('&_method=patch', '');
    const headers = scope.multipartFormDataValue
      ? { 'x-content-loader-request': 'true' }
      : { 'Content-Type': 'application/x-www-form-urlencoded', 'x-content-loader-request': 'true' };

    if (scope.abortController) {
      //@ts-expect-error headers are correctly created
      fetch(url, { body: postBody, headers: headers, method: 'post', signal: scope.abortController.signal })
        .then((response) => {
          if (response.redirected) {
            window.history.replaceState({},"", response.url);
            return {redirected: true, text: response.text()};
          }
          const eventName = response.headers.get('ksrt-trigger');
          if (eventName) {
            // using stimulus internal this.dispatch does not work as it for whatever reason prevents the morphing afterwards
            form.dispatchEvent(new Event(eventName, { bubbles: true }));
          }
          return {redirected: false, text: response.text()};
        })
        .then((template) => {
          if (template.redirected) {
            template.text.then((t) => document.body.innerHTML = t);
          } else {
            template.text.then((t) => {
              const el2 = document.createElement('div');
              el2.id = scope.morphElementIdValue;
              el2.innerHTML = t;

              morphdom(document.getElementById(scope.morphElementIdValue)!, el2, {
                childrenOnly: false,
                onBeforeElUpdated: function (fromEl) {
                  const activeEl = document.activeElement;
                    if(activeEl != null && activeEl.id == fromEl.id && activeEl instanceof HTMLInputElement && (activeEl as HTMLInputElement).type == 'text') {
                      return false;
                    }
                  return !fromEl.isEqualNode(document.querySelector('[data-morphdom-exclude]'));
                }
              });
            })
          }

        })
        .catch(function () {});
    } else {
      //@ts-expect-error headers are correctly created
      fetch(url, { body: postBody, headers: headers, method: 'post' })
        .then((response) => {
          if (response.redirected) {
            window.history.replaceState({},"", response.url);
            return {redirected: true, text: response.text()};
          }
          const eventName = response.headers.get('ksrt-trigger');
          if (eventName) {
            // using stimulus internal this.dispatch does not work as it for whatever reason prevents the morphing afterwards
            form.dispatchEvent(new Event(eventName, { bubbles: true }));
          }
          return {redirected: false, text: response.text()};
        })
        .then((template) => {
          if (template.redirected) {
            template.text.then((t) => document.body.innerHTML = t);
          } else {
            template.text.then((t) => {
              const el2 = document.createElement('div');
              el2.id = scope.morphElementIdValue;
              el2.innerHTML = t;

              morphdom(document.getElementById(scope.morphElementIdValue)!, el2, {
                childrenOnly: false,
                onBeforeElUpdated: function (fromEl) {
                  const forceInclude = fromEl.hasAttribute('data-morphdom-force-include');
                  const activeEl = document.activeElement;
                    if(activeEl != null && activeEl.id == fromEl.id && activeEl instanceof HTMLInputElement && (activeEl as HTMLInputElement).type == 'text') {
                      return false;
                    }
                  if (forceInclude) {
                    return true;
                  }
                  return !fromEl.isEqualNode(document.querySelector('[data-morphdom-exclude]'));
                }
              });
            })
          }
        })
        .catch(function () {});
    }
  }

  private debounce(callback: CallableFunction, delay = 100) {
    return (...args: unknown[]) => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  }

  // DO NOT EXTEND THIS CONTROLLER. IF YOU HAVE TO, YOU'RE DOING STH. WRONG!!!
  private ensureUrlWithSlashAtEnd(url: string) {
    return url.endsWith('/') ? url : `${url}/`;
  }
}
